.disable-style-btn {
  cursor: none; }

.btn-height-auto {
  height: auto !important; }

.parts-undo-button {
  width: 130px !important; }

.btn-setting {
  min-width: 90px;
  min-height: 40px;
  border-radius: 4px;
  font-size: 17px;
  margin: 0 auto;
  line-height: 19px;
  text-align: center;
  background: #fff;
  font-weight: 500;
  padding: 6px 18px;
  border: 1.5px solid #4dbce9;
  color: #4dbce9; }

.save-and-new,
.save,
.submit {
  border: 1.5px solid #6aa14e !important;
  color: #6AA14E !important;
  background-color: #ffffff !important; }

.save-and-new:hover,
.save:hover,
.submit:hover {
  border: 1.5px solid #6aa14e !important;
  color: #ffffff !important;
  background-color: #6AA14E !important; }

.delete,
.preview {
  border: 1.5px solid #B72E28 !important;
  color: #B72E28 !important;
  background-color: #ffffff !important; }

.delete:hover,
.preview:hover {
  border: 1.5px solid #B72E28 !important;
  color: #ffffff !important;
  background-color: #B72E28 !important; }
