/* sidebar profile selector css starts*/

#sidebarProfileSelector{
    display: none;
    padding: 15px 12px 12px 30px;
    background-color: black;
}

#user-image > img{
    width: 40px;
    border-radius: 50%;
    height: 40px;
}
#user-name{
    padding-left: 12px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
#user-name > span:first-child{
    color: white
}
#user-name > div > span:first-child{
    color:darkgray;
    font-size: 10px;
    min-width: 80px;
    padding-right: 10px;
}
#user-name > div{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-top: 5px;
}

.tgl-light + .tgl-btn{
    background-color:white;
}
label.tgl-btn{
    width:44px !important;
}
/* sidebar profile selector css ends */


.ham-menu-container{
    display: none;
}
.responsive-header-options{
    display: none;
}
/* Header sprite icon starts*/
    .header-sprite-menu{
        display: none
    }
    #search-icon{
        width: 0px !important;
        height: 25px;
        background-position: -545px -346px;
    }
    #filter-icon-container{
        height: 25px;
        width: 25px;
        margin-right: 15px;
    }
    #filter-icon{
        width: 25px ;
        height: 25px ;
        border: none ;
        background: transparent;
        outline: none;
    }
    #filter-icon:after{
        top:2px;
    }
    #add-icon{
        width: 25px;
        height: 25px;
        background-position: -55px -518px;
    }
    #reports-icon{
        width: 25px;
        height: 25px;
        background-position: -134px -129px;
    }
    .responsive-header-options > ul{
        list-style-type: none;
        margin: 0;
        padding: 0;
    }
    .responsive-header-options > ul > li{
        float: left;
        padding-right: 35px;
    }
    #email-icon{
        width: 25px;
        height: 25px;
        background-position: -26px -470px;
    }
    .show-search-bar{
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
        padding: 0 0 15px 0;
    }
    .show-search-bar > input{
        min-width: 0px;
       
    }
/* Header sprite icon ends*/

/* Tablet Screen */
 
@media (min-width: 768px) and (max-width: 1224px) {
    .responsive-header-options{
        display: block;
        position: absolute;
        right: 0;
    }
    .navbar.navbar-dark.flex-md-nowrap.fixed-top{
        background-color: black;
        padding-right: 15px;
    }
    .nav.navbar-login {
        background-color: #232323;
    }
    .profileselector{
        background-color: white;
    }
     .sidebar{
        position: absolute;
        margin-left: -300px;
        z-index: 99; /* z-index is high so that on visible can overlap main element */
        transition: all .2s ease-in;
        -webkit-transition: all .2s ease-in;
    }
    .header-logo{
       display: none !important ;
    }
    .ham-menu-container{
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align:center;
            -ms-flex-align:center;
                align-items:center;
        width:auto; 
        height: 100%;
        padding-left: 12px;
    }
    .ham-menu-container > .ham-icon > img{
        width:35px ; 
    }
    .ham-icon{
        padding: 0;
    }
    .ham-menu-container > span{
        width:100%;
        color: white; 
        font-size: 30px;
        padding-left: 10px;
    }

    div.breadcrumes  {
        display:none;
    }
    .right-nav-menu{
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: reverse;
            -ms-flex-direction: row-reverse;
                flex-direction: row-reverse;
    }
    .ham-menu-container .ham-icon{
        background: transparent;
        border: none;
        outline: none;
        cursor: pointer;
    }
}
/*   normal mobile devices */

@media(max-width: 767px){
    #sidebarProfileSelector{
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }
    .navbar.navbar-dark.flex-md-nowrap.fixed-top{
        background-color: black;
    }
    .nav.navbar-login {
        background-color: #232323;
    }
     .sidebar{
        position: absolute;
        margin-left: -300px;
        z-index: 99; /* z-index is high so that on visible can overlap main element */
        width: 300px;
        transition: all .2s ease-in;
        -webkit-transition: all .2s ease-in;

    }
    nav.navbar{
        padding: 0px;
    }
    .header-logo{
       display: none !important ;
    }
    .ham-menu-container > span{
        width:100%;
        color: white; 
        font-size: 24px;
        padding-left: 8px;
    }
    .ham-menu-container{
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align:center;
            -ms-flex-align:center;
                align-items:center;
        width:auto; 
        height: 100%;
        padding-left: 12px;
    }
    .ham-menu-container > .ham-icon > img{
        width:25px ; 
    }
    .right-nav-menu {
       display: none;   
    }
    #language-label-color{
        color: #fff;
    }
    .collapse-lists > a{
        height:62px;
    }

    .header-sprite-menu{
        display: block;
    }
    .responsive-header-options{
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }
    .ham-menu-container .ham-icon{
        background: transparent;
        border: none;
        outline: none;
        cursor: pointer;
    }
    .tax-heading-wrap .custom-drop-option:before{
        display: none;
    }
}

/* Small mobile device */
@media(max-width: 480px){
    .sidebar{
        position: absolute;
        /* display: none; */
        z-index: 9999; /* z-index is high so that on visible can overlap main element */        
    }
    .ham-menu-container > .ham-icon > img{
        width:24px ; 
    }
    .ham-menu-container > span{
        width:100%;
        color: white; 
        font-size: 16px;
    }
    
}
