button.search-chip.crossbtn {
  background-color: white; }

button.search-chip.crossbtn:focus {
  outline-color: transparent; }

.btn-add,
.add-qr-code,
.add-question {
  border: 1.5px solid #4dbce9 !important;
  color: #4dbce9 !important;
  background-color: #fff !important; }

.btn-add:hover,
.add-qr-code:hover,
.add-question:hover {
  border: 1.5px solid #4dbce9 !important;
  color: #fff !important;
  background-color: #4dbce9 !important; }
