#change-status-modal ul li {
  position: relative;
  cursor: pointer;
  height: 33px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding-right: 12px;
  padding-left: 12px; }

#selected-team-status {
  background: lightgray; }

#change-status-modal ul li p {
  margin: 0; }
