.SingleDatePickerInput__withBorder {
  border: 0px; }

.DateInput {
  width: 100%; }

.DateInput_input {
  background-color: #f2f2f2;
  color: rgba(76, 76, 76, 0.6);
  border: 1px solid #CBD1D5;
  border-radius: 4px; }

.CalendarDay {
  border: 0px; }

.CalendarDay__selected {
  background: #B71B1B;
  color: white;
  border-radius: 30px; }

.CalendarDay__selected:hover {
  background: #B71B1B;
  color: white;
  border-radius: 30px; }

.sprite-icon-before:before {
  z-index: 2; }

.react-datepicker-popper {
  z-index: 3; }

.react-datepicker {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }
