.offline-mode-option-modal-body-container {
    text-align: center;
}

.offline-mode-option-modal-body-wrapper {
    display: inline-block;
}

.offline-mode-options-list {
    text-align: left;
}

.select-customer-modal-buttons-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}

.is-customer {
    -ms-flex-pack: distribute;
        justify-content: space-around;
}

.offline-customer-list-table-container {
    margin-top: -31px;
}

.offline-customer-list-table-wrapper {
    border: 0px !important;
}

.posi-relative .offline-customer-list-table-delete-icon {
    position: absolute;
    margin-left: 12px;
    margin-bottom: -5px;
    right: 0.5vw;
}

button.offline-customer-list-table-start-btn.btn.btn_primary {
    min-width: 120px;
}

.search-container .mw-325 {
    min-width: 325px;
}

.offline-job-details span#Q, .offline-job-details span#I, .offline-job-details span#WO {
    position: initial;
    margin-top: 5%;
}

.offline-job-details .offline-dnd-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-right: 0px;
}

.offline-job-details .offline-job-row {
    padding-left: 15px !important;
}

.offline-job-details .offline-job-row .offline-dnd-row .c-checkboc {
    width: 290px;
}

.global-table .table tbody tr td table tbody tr td:first-child .offline-dnd-row label.cust-checkbox .checkmark {
    width: 20px;
}

.card-header.offline-enhanced {
    border-bottom: 0px;
}

.delete-icon:hover:after {
    z-index: 999;
}

@media (max-width: 460px){
    .global-table .table tbody tr td table tbody tr td:first-child .offline-dnd-row label.cust-checkbox .checkmark {
        width: 20px;
        top: 12px;
    }
    
}