.calender-date-popover .popover-title {
  font-size: 16px;
  color: #4c4c4c;
  font-weight: 600;
  white-space: normal;
  word-break: break-all;
  cursor: pointer;
  width: 225px; }

.calender-date-popover .popover-type {
  display: block;
  background: #f2f2f2;
  padding: 6px 12px;
  bottom: -13px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  overflow: hidden;
  position: relative;
  width: calc(100% + 32px);
  margin-left: -16px;
  padding-left: 30px; }

.calender-date-popover .popover-quote {
  position: absolute;
  right: 15px;
  top: 20px;
  background: #6AA14E;
  border-radius: 50%;
  text-align: center;
  color: #fff;
  padding: 4px;
  display: inline-block;
  min-width: 28px; }

#I {
  background: #b71b1b; }

#W {
  background: #4dbce9; }

.calender-pop-date {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  display: -webkit-flex;
  -ms-flex-item-align: center;
      align-self: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }

.calender-pop-date .popover-hour {
  font-size: 26px;
  color: #4c4c4c;
  font-weight: 400; }

.calender-pop-date .popover-day {
  font-size: 12px;
  color: #4c4c4c; }

.calender-pop-date .start-date {
  margin-right: 16px; }

.calender-pop-date .end-date {
  margin-left: 16px; }

.calender-date-popover .popover-type::before {
  content: "";
  width: 8px;
  height: 8px;
  border: 1px solid #ccc;
  border-radius: 50%;
  position: absolute;
  left: 16px;
  top: 12px; }
