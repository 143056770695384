.inspectbutton{
    -webkit-box-shadow: 1px 1px 4px 0 rgba(0,0,0,0.13);
            box-shadow: 1px 1px 4px 0 rgba(0,0,0,0.13);
    background-color: #FFFFFF;
    border-radius: 4px;
    min-height: 30px;
    border: 1px solid #6AA14E;
    width: auto;
    min-width: auto;
    font-weight: normal;
    color: #6AA14E;
    padding: 5px 12px;
}
.cursor{
    cursor: 'pointer';
}