.search{
    width:150px;

}
.float{
    float: right;
    margin-bottom: 30px;
}
#deficiencyStatusCell{
    width:13%;
}
#deficiencyfirstchildwidth{
    width: 20%;
}
#reportfirstchildwidth{
    width: 8%;
}
.Taskrowposition{
    width: 200px;
    left: -200px;
}