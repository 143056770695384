.chat-wrap .people-list::-webkit-scrollbar {
  width: 0; }

.chat-wrap .chat .chat-history::-webkit-scrollbar {
  width: 0; }

.chat-wrap .message-received {
  margin-right: 80px; }

.chat-wrap .message-sent {
  margin-left: 80px; }

.chat-wrap-loader {
  position: absolute;
  right: 0; }

.chat-wrap .send-btn-disabled {
  opacity: 0.5;
  cursor: default; }

.chat-wrap .message-received .profile-photo {
  left: -45px; }

.chat-wrap .message-sent .profile-photo {
  right: -45px; }

.chat-wrap .profile-photo {
  border-radius: 50%;
  width: 44px;
  height: 44px;
  overflow: hidden;
  background-color: #f2f2f2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  .chat-wrap .profile-photo .profile-photo-text {
    font-size: 14px;
    color: #4C4C4C;
    font-weight: 500;
    margin-top: 1px; }

.chat-wrap .chat-header .profile-photo-text {
  font-size: 11px; }

.chat-wrap .message {
  min-width: 90px; }
  .chat-wrap .message .profile-photo {
    position: absolute;
    top: 0; }
  .chat-wrap .message .profile-photo-text {
    font-size: 11px; }

.chat-wrap .open-new-chat-list .about {
  margin-top: 0; }

.chat-wrap .people-list li {
  cursor: pointer; }

.chat-wrap .chat-system-message {
  text-align: center;
  font-size: 14px;
  padding: 20px 0;
  font-weight: 500; }

.chat-wrap .chat-person-tag-container {
  padding-bottom: 25px; }

.chat-wrap .search-chip {
  margin-right: 5px;
  margin-bottom: 3px; }

.chat-wrap .search-chip-disabled {
  border: 1px solid #c0c0c0;
  color: #c0c0c0;
  cursor: auto; }

.chat-wrap .create-group-chat input {
  border-radius: 4px 0 0 4px; }

.chat-wrap .create-group-chat button {
  background: #B71B1B;
  width: 75px;
  height: 48px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border: none;
  color: white; }

.chat-wrap .open-new-chat-list {
  min-height: calc(100vh - 160px);
  max-height: 509px;
  overflow: scroll;
  width: initial;
  min-width: 320px;
  background: #fff;
  margin-right: 0;
  border: 1px solid #CBD1D5;
  border-radius: 4px; }

.chat-wrap .chat-message-status-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1; }

.chat-wrap .chat-message-status {
  color: #B71B1B; }

.no-chat-data {
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  padding-top: 40px;
  text-align: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #b71b1b;
  padding: 25px 15px; }

@media (max-width: 900px) and (min-width: 600px) {
  .chat-wrap .open-new-chat-list {
    min-height: calc(100vh - 200px); } }
