.topLayer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between; }

.bottomLayer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between; }

.datepicker {
  margin-left: 58%;
  margin-bottom: 20px; }

.taskheading {
  font-size: 30px; }

.filter-width {
  width: 1000px; }

.topright {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  display: -webkit-flex; }

.margin {
  margin-top: 0px; }

.wid {
  width: 50px; }

.picker {
  position: relative;
  margin-top: 0px; }

.nullborder {
  border: 0px; }

@media (max-width: 480px) {
  .topright {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex; }
  .toprightWithoutcal {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end; }
  .task-header-topright {
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: end; }
    .task-header-topright .picker {
      margin-left: 5px; }
      .task-header-topright .picker button {
        margin-left: unset; } }
