.pageBackground {
    background: #E4E5E6;
}

.termsConditionCard {
    margin-left: 10%;
    margin-right: 10%;
    padding-bottom: 20px;
    margin-top: 55px;
}

.headerOfTermsCondition {
    background: #B71B1B;
    color: white;
    text-align: center;
}
.terms-condtions-content-area {
    text-align: justify;
    padding: 20px;
}

.desrcipt {
    margin-left: 16px;
}

ul.list-style {
    list-style-type: disc;
}

.checkbox-for-terms-and-condn {
    color: red;
    padding-left: 18px;
    font-size: 18px !important  ;
}

.Proceed-btn {
    -ms-flex-item-align: center;
        align-self: center;
    padding-top: 18px;
}

.cross-btn-term-condn {
    -webkit-box-align: right;
        -ms-flex-align: right;
            align-items: right;
}