.daterange{
    position: absolute;
    top: 46px;
    right: 1px;
    -webkit-box-shadow: none;
            box-shadow: none;
    border-radius: 3px;
}
.rdr-Calendar{
    height: 325px;
}
.resetbuttondaterange{
    top: 336PX;
    left: -71PX;
    position: absolute;
    z-index: 999;
    font-size: 14px;
    border-radius: 3px;
    color: white;
    border: 1px solid white;
    background-color: #E74C3C;

}