 /* Lower Desktop */

 @media (min-width: 768px) and (max-width: 1224px) { 
    .main-container{
        width:100%;
        padding: 30px 30px 0px 30px;
    }
    
    .navbar{
        padding-right: 30px !important;
    }  
    h1.settingheading{
        margin: 0 0 12px 0;
        padding-left: 30px;
    }
} 