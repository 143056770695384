.job-list-table-special span.job-details-hover-text:before {
  content: "";
  width: 0;
  height: 0;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 6px solid #4c4c4c;
  position: absolute;
  top: 85px;
  right: -6px; }

.job-list-table-special.global-table .table tbody tr td table tbody tr.job-details-hover:hover .job-details-hover-text {
  display: inline-block;
  background-color: #4c4c4c;
  color: #fff;
  text-align: center;
  padding: 6px 8px;
  border-radius: 4px;
  position: absolute;
  font-size: 14px;
  overflow: visible;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  white-space: normal;
  top: -85px; }

.job-list-table-special.global-table .table tbody tr td table tbody tr.job-details-hover:hover .job-details-hover-text ul {
  text-align: left; }

.job-list-table-special.global-table .table tbody tr td table tbody tr.job-details-hover:hover .job-details-hover-text ul li {
  padding: 4px 0 4px;
  line-height: 16px;
  font-size: 12px;
  font-weight: normal; }

.job-list-table-special.global-table .table tbody tr td table tbody tr span.job-details-hover-text {
  display: none;
  width: 200px;
  position: absolute;
  left: -168px; }

.job-list-table-special .table tbody tr td table tbody tr.job-details-hover > td {
  position: relative; }

@media (max-width: 551px) {
  .job-list-table-special span.job-details-hover-text:before {
    position: static;
    top: auto;
    right: auto; }
  .job-list-table-special.global-table .table tbody tr td table tbody tr.job-details-hover:hover .job-details-hover-text {
    position: static;
    top: auto; }
  .job-list-table-special.global-table .table tbody tr td table tbody tr span.job-details-hover-text {
    position: static;
    left: auto; }
  .job-list-table-special .table tbody tr td table tbody tr.job-details-hover > td {
    position: static; } }
